import type { CSS } from "@stitches/react";
import { createStitches, keyframes } from "@stitches/react";
import { reset } from "stitches-reset";

const pulseAnimation = keyframes({
  "0%": { opacity: 1 },
  "50%": { opacity: 0.5 },
  "100%": { opacity: 1 },
});

// Set global/generic styles
export const { css, styled, globalCss, getCssText, config } = createStitches({
  theme: {
    colors: {
      hoken: "#00A1AB",
      chalk: "#FCFFFF",
      alice: "#F5F9FF",
      black: "#000000",
      white: "#ffffff",
      primary: "#4033D0",
      amber: "#FABB05",
      hokenDerivative: "#00A1AB",
      lightPurple: "#E5E2FF",
      grey05: "#F3F2F8",
      grey85: "#D9D9D9",
      grey20: "#E6E5EF",
      grey60: "#C0BFCD",
      grey70: "#817F8F",
      grey90: "#1E1E23",
      green: "#5BFCB8",
      green20: "#3F8D6D",
      greenTint: "#3FD797",
      red: "#D6000033",
      red20: "#E72525",
      red32: "#FD3232",
      attentionGreen: "#00A538",
      "hoken-core-purple-gray": "$grey05",
      "hoken-core-purple-gray-secondary": "#E6E5EF",
      "hoken-core-secondary-color": "$green",
      "hoken-core-text-color": "$black",
      "hoken-core-red-error": "$red",
      "hoken-core-attention-green": "$attentionGreen",
      "hoken-core-input-background": "$grey90",
      "hoken-core-secondary-black": "#17171A",
      "hoken-core-greyscale-05": "$grey05",
      "hoken-core-greyscale-60": "#C0BFCD",
      "hoken-core-greyscale-75": "#636369",
      "hoken-core-greyscale-80": "#363539",
      "hoken-core-warning-20": "#FFF4E2",
      "hoken-core-warning-100": "#E88F3E",
      "hoken-core-mirage": "#151521",
      "hoken-core-light-purple": "#948AFF",
      opacityGray: "rgb(107 114 128 / .5)",
    },
    fonts: {
      helvetica: "helvetica neue pro, sans-serif",
      franklin: "libre franklin, sans-serif",
    },
    fontSizes: {
      xs: "0.75rem",
      small: "0.8125rem",
      medium: "1rem",
      large: "1.125rem",
      xlarge: "1.25rem",
      xxlarge: "1.875rem",
      // New branding tokens
      "hoken-core-font-10": "0.625rem",
      "hoken-core-font-11": "0.6875rem",
      "hoken-core-font-12": "0.75rem",
      "hoken-core-font-13": "0.8125rem",
      "hoken-core-font-14": "0.875rem",
      "hoken-core-font-15": "0.9375rem",
      "hoken-core-font-16": "1rem",
      "hoken-core-font-18": "1.125rem",
      "hoken-core-font-20": "1.25rem",
      "hoken-core-font-24": "1.5rem",
      "hoken-core-font-28": "1.75rem",
      "hoken-core-font-32": "2rem",
      "hoken-core-font-36": "2.25rem",
      "hoken-core-font-54": "3.375rem",
      "hoken-core-font-64": "4rem",
      "hoken-core-font-84": "5.25rem",
    },
    fontWeights: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
      black: 900,
    },
    space: {
      0: "0px",
      1: "0.25rem",
      2: "0.5rem",
      3: "0.75rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      8: "2rem",
      "hoken-core-space-none": "0px",
      "hoken-core-space-small": "0.5rem",
      "hoken-core-space-2": "0.125rem",
      "hoken-core-space-4": "0.25rem",
      "hoken-core-space-5": "0.3125rem",
      "hoken-core-space-8": "0.5rem",
      "hoken-core-space-10": "0.625rem",
      "hoken-core-space-12": "12.5px",
      "hoken-core-space-14": "14px",
      "hoken-core-space-16": "1rem",
      "hoken-core-space-18": "1.125rem",
      "hoken-core-space-22": "1.375rem",
      "hoken-core-space-23": "1.4375rem",
      "hoken-core-space-24": "1.5rem",
      "hoken-core-space-27": "1.6875rem",
      "hoken-core-space-32": "2rem",
      "hoken-core-space-36": "2.25rem",
      "hoken-core-space-40": "2.5rem",
      "hoken-core-space-57": "3.5625rem",
      "hoken-core-space-64": "4rem",
      "hoken-core-space-80": "5rem",
      "hoken-core-space-124": "124px",
    },
    sizes: {
      1: "40rem",
      2: "48rem",
      3: "64rem",
      max: "96rem",
      "hoken-core-icon-width": "12px",
      "hoken-core-icon-14": "14px",
      "hoken-core-icon-height": "15px",
    },
    radii: {
      rounded: "0.25rem",
      "hoken-core-border-radius-4": "4px",
      "hoken-core-border-radius-8": "8px",
      "hoken-core-border-radius-9": "9px",
      "hoken-core-border-radius-10": "10px",
      "hoken-core-border-radius-20": "20px",
    },
    lineHeights: {
      base: "1rem",
      medium: "1.5rem",
      large: "1.75rem",
      xxlarge: "2.25rem",
      "hoken-core-line-height-small": "$base",
      "hoken-core-line-height-12": "12px",
      "hoken-core-line-height-15": "15px",
      "hoken-core-line-height-16": "16px",
      "hoken-core-line-height-17": "17px",
      "hoken-core-line-height-19": "19px",
      "hoken-core-line-height-24": "24px",
      "hoken-core-line-height-25": "25px",
      "hoken-core-line-height-29": "29px",
      "hoken-core-line-height-36": "36px",
      "hoken-core-line-height-43": "43px",
      "hoken-core-line-height-56": "56px",
      "hoken-core-line-height-100": "100px",
    },
    shadows: {
      "hoken-core-text-shadow": "0px 0px 10px #000000",
    },
  },
  media: {
    belowMinimumWidth: "(max-width: 375px)",
    /** Width breakpoints */
    bp1: "(min-width: 640px)",
    isMobile: "(max-width: 767px)",
    isTablet: "(min-width: 768px) and (max-width: 1024px)",
    isDesktop: "(min-width: 1024px)",
    bp2: "(min-width: 768px)",
    bp3: "(min-width: 1024px)",
    /** Height breakpoints */
    bph1: "(min-height: 640px)",
    /** Others */
    reduceMotion: "(prefers-reduced-motion: reduce)",
  },
  utils: {
    truncate: (value: string) => {
      if (value === "off")
        return {
          width: "100%",
          whiteSpace: "normal",
          overflow: "inherit",
        };

      return {
        width: value,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      };
    },
    loader: (overrides: CSS) => {
      return {
        animation: `${pulseAnimation} 2s ease infinite`,
        animationDelay: "1",
        borderRadius: "$rounded",
        boxSizing: "border-box",
        position: "relative",
        ...overrides,
        "@reduceMotion": {
          animation: "none",
        },
      };
    },
    gradientBorder: ({ overrides = {} }: { overrides?: CSS }) => {
      const { backgroundColor = "$white", ...otherOverrides } = overrides;
      return {
        background: `linear-gradient(${backgroundColor}, ${backgroundColor}) padding-box,linear-gradient(to right, $green, $primary) border-box`,
        borderRadius: "20px",
        border: "2px solid transparent",
        ...otherOverrides,
      };
    },
  },
});

export const globalStylesReset = globalCss({
  ...reset,
  ":root": {
    "--marquee-height": "0rem",
  },
  html: {
    scrollBehavior: "smooth",
  },
  body: {
    fontFamily: "$franklin",
  },
  "@font-face": [
    {
      fontFamily: "helvetica neue pro",
      fontWeight: 400,
      fontDisplay: "swap",
      src: "url('/fonts/helvetica-neue/regular-extended/font.woff2') format('woff2'), url('@hoken/web/public/fonts/helvetica-neue/regular-extended/font.woff') format('woff')",
    },
    {
      fontFamily: "helvetica neue pro",
      fontWeight: 700,
      fontDisplay: "swap",
      src: "url(/fonts/helvetica-neue/bold-extended/font.woff2) format('woff2'), url(@hoken/core/styles/fonts/helvetica-neue/bold-extended/font.woff) format('woff')",
    },
    {
      fontFamily: "Libre Franklin",
      fontWeight: "100 900",
      fontDisplay: "swap",
      src: "url(/fonts/libre-franklin/LibreFranklin-VariableFont_wght.ttf)",
    },
  ],
});
