import { varsHoken } from "@hoken/design-system/themes";
import { recipe } from "@vanilla-extract/recipes";

// TODO: The variants are becoming unreadable and hard to maintain.
// https://linear.app/hoken/issue/HOK-1115/text-component-variants-are-becoming-unreadable
export const variants = {
  type: {
    H1: {
      fontFamily: varsHoken.fonts.family.helvetica,
      fontWeight: varsHoken.fonts.weight.regular,
      fontSize: varsHoken.fonts.sizes[32],
      lineHeight: varsHoken.fonts.lineHeights.small,
      letterSpacing: "-0.005rem",
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[56],
        },
      },
    },
    H2: {
      fontFamily: varsHoken.fonts.family.helvetica,
      fontWeight: varsHoken.fonts.weight.regular,
      fontSize: varsHoken.fonts.sizes[24],
      lineHeight: varsHoken.fonts.lineHeights.small,
      letterSpacing: "-0.08px",
      "@media": {
        "screen and (min-width: 768px)": {
          fontSize: varsHoken.fonts.sizes[32],
        },
      },
    },
    H3: {
      fontFamily: varsHoken.fonts.family.helvetica,
      fontWeight: varsHoken.fonts.weight.regular,
      fontSize: varsHoken.fonts.sizes[18],
      lineHeight: varsHoken.fonts.lineHeights.small,
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[24],
        },
      },
    },
    H4: {
      fontFamily: varsHoken.fonts.family.helvetica,
      fontWeight: varsHoken.fonts.weight.regular,
      fontSize: varsHoken.fonts.sizes[14],
      lineHeight: varsHoken.fonts.lineHeights.small,
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[20],
        },
      },
    },
    H4Small: {
      fontFamily: varsHoken.fonts.family.helvetica,
      fontWeight: varsHoken.fonts.weight.regular,
      fontSize: varsHoken.fonts.sizes[12],
      lineHeight: varsHoken.fonts.lineHeights.small,
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[18],
        },
      },
    },
    H5: {
      fontFamily: varsHoken.fonts.family.franklin,
      fontWeight: varsHoken.fonts.weight.regular,
      fontSize: varsHoken.fonts.sizes[16],
      lineHeight: varsHoken.fonts.lineHeights.small,
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[18],
        },
      },
    },
    H5Alt: {
      fontFamily: varsHoken.fonts.family.helvetica,
      fontWeight: varsHoken.fonts.weight.regular,
      fontSize: varsHoken.fonts.sizes[14],
      lineHeight: varsHoken.fonts.lineHeights.medium,
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[16],
        },
      },
    },
    Hero: {
      fontFamily: varsHoken.fonts.family.helvetica,
      fontWeight: varsHoken.fonts.weight.bold,
      fontSize: varsHoken.fonts.sizes[24],
      lineHeight: varsHoken.fonts.lineHeights.small,
      textTransform: "uppercase" as const,
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[32],
        },
      },
    },
    P1: {
      fontFamily: varsHoken.fonts.family.franklin,
      fontSize: varsHoken.fonts.sizes[16],
      lineHeight: varsHoken.fonts.lineHeights.medium,
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[18],
        },
      },
    },
    P2: {
      fontFamily: varsHoken.fonts.family.franklin,
      fontSize: varsHoken.fonts.sizes[14],
      lineHeight: varsHoken.fonts.lineHeights.medium,
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[16],
        },
      },
    },
    P2Small: {
      fontFamily: varsHoken.fonts.family.franklin,
      lineHeight: varsHoken.fonts.lineHeights.medium,
      fontSize: varsHoken.fonts.sizes[12],
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[14],
        },
      },
    },
    P3: {
      fontFamily: varsHoken.fonts.family.franklin,
      fontSize: varsHoken.fonts.sizes[12],
      lineHeight: varsHoken.fonts.lineHeights.medium,
    },
    P3Medium: {
      fontFamily: varsHoken.fonts.family.franklin,
      fontSize: varsHoken.fonts.sizes[11],
      lineHeight: varsHoken.fonts.lineHeights.medium,
    },
    P3Small: {
      fontFamily: varsHoken.fonts.family.franklin,
      fontSize: varsHoken.fonts.sizes[10],
      lineHeight: varsHoken.fonts.lineHeights.medium,
    },
    P4: {
      fontFamily: varsHoken.fonts.family.franklin,
      fontSize: varsHoken.fonts.sizes[10],
      lineHeight: varsHoken.fonts.lineHeights.medium,
    },
    Huge: {
      fontFamily: varsHoken.fonts.family.helvetica,
      fontSize: "2.3rem",
      lineHeight: varsHoken.fonts.lineHeights.xsmall,
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: "5rem",
        },
      },
    },
    TourTitle: {
      fontFamily: varsHoken.fonts.family.helvetica,
      fontWeight: varsHoken.fonts.weight.regular,
      fontSize: varsHoken.fonts.sizes[32],
      lineHeight: varsHoken.fonts.lineHeights.small,
      letterSpacing: "-0.005rem",
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[48],
        },
      },
    },

    BannerTitle: {
      fontFamily: varsHoken.fonts.family.helvetica,
      fontSize: varsHoken.fonts.sizes[48],
      lineHeight: varsHoken.fonts.lineHeights.small,
      "@media": {
        "screen and (min-width: 1024px)": {
          fontSize: varsHoken.fonts.sizes[56],
        },
      },
    },
  },
  icon: {
    wrap: {
      display: "flex",
      gap: varsHoken.space["4"],
      alignItems: "center",
    },
  },
  decoration: {
    underline: {
      textDecoration: "underline",
    },
    capitalize: {
      // typescript issue: https://github.com/mui/material-ui/issues/16307#issuecomment-1149081580
      textTransform: "capitalize" as const,
    },
    uppercase: {
      // typescript issue: https://github.com/mui/material-ui/issues/16307#issuecomment-1149081580
      textTransform: "uppercase" as const,
    },
    none: {
      textDecoration: "none",
    },
  },
  cursor: {
    pointer: {
      cursor: "pointer",
    },
  },
  alignment: {
    left: {
      // typescript issue: https://github.com/mui/material-ui/issues/16307#issuecomment-1149081580
      textAlign: "left" as const,
    },
    right: {
      // typescript issue: https://github.com/mui/material-ui/issues/16307#issuecomment-1149081580
      textAlign: "right" as const,
    },
    center: {
      // typescript issue: https://github.com/mui/material-ui/issues/16307#issuecomment-1149081580
      textAlign: "center" as const,
    },
  },
  marginBottom: {
    small: {
      marginBottom: varsHoken.space.small,
    },
    medium: {
      marginBottom: varsHoken.space.medium,
    },
    large: {
      marginBottom: varsHoken.space.large,
    },
  },
  // Iterate through all the font weights and create a variant for each
  weight: (() => {
    const newWeightObject = {} as {
      [key in keyof typeof varsHoken.fonts.weight]: {
        fontWeight: keyof typeof varsHoken.fonts.weight;
      };
    };

    for (const key in varsHoken.fonts.weight) {
      // @ts-expect-error some type issue
      const value = varsHoken.fonts.weight[key];
      // @ts-expect-error some type issue
      newWeightObject[key] = {
        fontWeight: value,
      };
    }

    return newWeightObject;
  })(),
  // Iterate through all the font families and create a variant for each
  font: (() => {
    const newFontObject = {} as {
      [key in keyof typeof varsHoken.fonts.family]: {
        fontFamily: keyof typeof varsHoken.fonts.family;
      };
    };

    for (const key in varsHoken.fonts.family) {
      // @ts-expect-error some type issue
      const value = varsHoken.fonts.family[key];
      // @ts-expect-error some type issue
      newFontObject[key] = {
        fontFamily: value,
      };
    }

    return newFontObject;
  })(),
  // Iterate through all the font families and create a variant for each
  colors: (() => {
    const newColorObject = {} as {
      [key in keyof typeof varsHoken.colors]: {
        color: keyof typeof varsHoken.colors;
      };
    };

    for (const key in varsHoken.colors) {
      // @ts-expect-error some type issue
      const value = varsHoken.colors[key];
      // @ts-expect-error some type issue
      newColorObject[key] = {
        color: value,
      };
    }

    return newColorObject;
  })(),
  lineHeight: {
    xsmall: {
      lineHeight: varsHoken.fonts.lineHeights.xsmall,
    },
    small: {
      lineHeight: varsHoken.fonts.lineHeights.small,
    },
    medium: {
      lineHeight: varsHoken.fonts.lineHeights.medium,
    },
    large: {
      lineHeight: varsHoken.fonts.lineHeights.large,
    },
  },
};

export const text = recipe({
  base: {
    color: "inherit",
  },

  variants: variants,
});
